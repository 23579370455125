<template>
    <div class="admin_page_body">
        <el-form ref="form" :inline="true" :model="form" size="small" :rules="rules" label-position="top">
            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">基本信息</h1>
                <div class="data_detail mt24">
                    <el-form-item label="用户手机号" prop="phone" class="full">
                        <el-input v-model="form.phone" placeholder="请输入用户手机号" style="width: 357px"></el-input>
                    </el-form-item>
                </div>

                <div class="data_detail mt24">
                    <el-form-item label="用户角色" prop="roleId">
                        <el-select v-model.number="form.roleId" placeholder="请选择用户角色">
                            <el-option v-for="(item, index) in unitList" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>

            <div class="page_footer mt24">
                <el-button type="primary" size="small" @click="submitForm('form')" :loading="isSubmitLoading">提交</el-button>
                <el-button type="default" size="small" @click="$router.go(-1)">取消</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
  import {postBuying} from "../../../api/admin/supplyBuying";

  export default {
    name: "adminUserAdd",
    data () {
      return {
        form: {
          phone: '',
          roleId: '',
        },
        rules: {
          title: [
            { required: true, message: '请输入标题', trigger: 'blur' }
          ],
          contactPhone: [
            { required: true, message: '请输入联系电话', trigger: 'blur' },
            { pattern: '^([1]\\d{10}|([\\(（]?0[0-9]{2,3}[）\\)]?[-]?)?([2-9][0-9]{6,7})+(\\-[0-9]{1,4})?)$', message: '请输入正确的联系电话', trigger: 'blur'}
          ],
        },
        isSubmitLoading: false
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
    },
    methods: {
      submitForm (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isSubmitLoading = true
            postBuying({
              data: {
                title: this.form.title,
                goodName: this.form.goodName,
                num: this.form.num,
                unitId: this.form.unitId,
                unitName: this.unitList.filter(item => item.id === this.form.unitId)[0].name,
                sku: this.form.sku,
                imageUrl: this.form.imageUrl,
                stopAt: this.form.stopAt,
                receiptAt: this.form.receiptAt,
                price: this.form.price,
                priceStatus: this.form.priceStatus,
                content: this.form.content,
                contactName: this.form.contactName,
                contactPhone: this.form.contactPhone,
                contactStatus: this.form.contactStatus,
                status: this.form.status
              }
            }).then(res => {
              this.isSubmitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: '采购单发布成功',
                  showClose: true,
                  type: 'success'
                })
                this.$router.go(-1)
              }
            }).catch(() => {
              this.isSubmitLoading = false
            })
          } else {
            return false;
          }
        });
      }
    },
    components: {}
  }
</script>
